<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      :finish-button-text="$t('Save')"
      back-button-text="Previous"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
      @on-change="stepChanged"
    >
      <!-- account datails tab -->
      <!-- <tab-content :title="$t('General')">
        <form-mint-lease-summary :show_card="true" :lease="lease" />
      </tab-content> -->

      <!-- financial tab -->
      <tab-content :title="$t('NFT Financial')">
        <form-mint-financial
          :show_card="true"
          :lease="lease"
          :nftFormData.sync="nftFormData"
        />
      </tab-content>

      <!-- legal terms tab -->
      <tab-content :title="$t('NftContractTypes').title">
        <form-mint-nft-contract
          :show_card="true"
          :lease="lease"
          :nftFormData.sync="nftFormData"
        />
      </tab-content>

      <tab-content :title="$t('Review Entities')">
        <mint-entities-summary :lease="lease" />
      </tab-content>

      <tab-content :title="$t('Initial Allocation')">
        <form-mint-initial-allocation
          :lease="lease"
          :nftFormData.sync="nftFormData"
          :allocations.sync="allocations"
        />
        <!-- :allocations.sync="allocations" -->
      </tab-content>

      <tab-content :title="$t('Confirm')">
        <form-mint-nft-confirm
          :lease="lease"
          :nftFormData.sync="nftFormData"
          :allocations.sync="allocations"
          :isSubmitting="isSubmitting"
        />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import { saveToLocal, getLocal, updateArrElemById } from "@/libs/helpers";

import FormMintLeaseSummary from "@/views/forms/components/form-mint/FormMintLeaseSummary.vue";
import FormMintFinancial from "@/views/forms/components/form-mint/FormMintFinancial.vue";
import FormMintNftContract from "@/views/forms/components/form-mint/FormMintNftContract.vue";
import FormMintNftConfirm from "@/views/forms/components/form-mint/FormMintNftConfirm.vue";
import MintEntitiesSummary from "@/views/forms/components/form-mint/MintEntitiesSummary.vue";
import FormMintInitialAllocation from "@/views/forms/components/form-mint/FormMintInitialAllocation.vue";

import Ripple from "vue-ripple-directive";

import laravel from "@/libs/laravel";

import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormDatepicker,
  BCardText,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DragDropCloneVue from "@/views/extensions/drag-drop/DragDropClone.vue";
import { sync } from "vuex-pathify";
import { getArrElemById, sortEntitiesArrayByType } from "@/libs/helpers";
import * as init_leases from "@/libs/init_leases";
import * as init_nfts from "@/libs/init_nfts";

let mintData = {
  supply: 100000, // default 100k
  contract: {
    contract_type: null,
    contract_files: [],
  },
  lease_id: null,

  entities: [],
  // old stuff
  property_type: "",
  user_id: "",
  lease_type: "",
  address: "",
  // "address": cloneStruct(address),
  description: "",
  terms_type: "",
  start_date: "",
  end_date: "",
  paym_freq: "",
  paym_freq_cnt: "",
  paym_freq_amt: "",
  billing_day: "",
  increaseType: "",
  increaseValue: "",
  bond: "",
  prepay_type: "",
  prepay_value: "",
  latepay_type: "",
  latepay_value: "",
  status: "draft",
  data: "",

  // entities
  leasors: [], // entities
  leasees: [], // entities
  guarantors: [], // entities
  documents: [],
};

export default {
  directives: {
    Ripple,
  },
  components: {
    FormMintLeaseSummary,
    FormMintFinancial,
    FormMintNftContract,
    FormMintNftConfirm,
    MintEntitiesSummary,
    FormMintInitialAllocation,

    BFormSelect,
    BFormTextarea,
    BCardText,
    BFormDatepicker,

    FormWizard,
    TabContent,

    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      lease: null,
      // user: this.$store.state.user.profile,
      // lease: null,
      nftFormData: mintData,
      allocations: [],
      isSubmitting: false,
    };
  },
  props: {
    leaseId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    user: sync("user/profile"),
    leases: sync("leases/list"),
  },
  watch: {
    // whenever question changes, this function will run
    lease(newLease, oldLease) {
      // lease changed
      console.log("oldLease: ", oldLease);
      console.log("newLease: ", newLease);
      this.nftFormData.entities = newLease.entities;

      let allocations = [];
      let creator_act_as = "creator";
      let entities = this.nftFormData.entities;
      let creator_entity_name;
      for (let i = 0; i < entities.length; i++) {
        if (entities[i].email == this.user.email) {
          creator_act_as = entities[i].act_as;
          creator_entity_name = entities[i].fullname;
          // entities[i].is_creator = true;
        }
        if (entities[i].act_as == "leasor") {
          // initialise allocation
          entities[i].allocation = 0;
          allocations.push(entities[i]);
        }
      }

      if (
        this.user &&
        this.lease
        // && isCreatorAnEntity(this.user, this.nftFormData.entities)
      ) {
        console.log("creator act as: ", creator_act_as);
        // only push if lease owner is not already specified in the entity list emails
        allocations.push(
          this.getLeaseOwnerEntity(
            creator_act_as,
            creator_entity_name ? creator_entity_name : this.user.name
          )
        );
      }
      console.log("allocations: ", allocations);
      this.allocations = JSON.parse(JSON.stringify(allocations));
    },
  },
  mounted() {
    console.log("mint nft leaseid:", this.leaseId);
    // edit mode
    if (this.leaseId > 0) {
      laravel.leases
        .show(this.leaseId)
        .then((resp) => {
          console.log("found edit lease resp: ", resp);
          let leaseObj = JSON.parse(JSON.stringify(resp.data));
          if (leaseObj && leaseObj.user_id != this.user.id) {
            // cannot edit lease that dont below to user
            console.log("user not owner of lease");
            throw "User not owner of lease.";
          }
          let sortedArrs = sortEntitiesArrayByType(leaseObj.entities);
          leaseObj.leasors = sortedArrs.leasors;
          leaseObj.leasees = sortedArrs.leasees;
          leaseObj.guarantors = sortedArrs.guarantors;
          leaseObj.documents = leaseObj.files;
          this.lease = leaseObj;

          let localdata = getLocal("nftFormData");
          if (localdata && localdata.lease_id == leaseObj.id) {
            this.nftFormData = localdata;
          } else {
            mintData.lease_id = leaseObj.id;
            this.nftFormData = mintData;
          }

          console.log("found edit new formdata: ", this.nftFormData);
        })
        .catch((error) => {
          console.log("Error: ", error.message);
          // route back toast then route back

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error editing lease.",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          setTimeout(() => this.$router.go(-1), 2000);
        });
    } else {
      // create mode
      console.log("mounted load from local: ", getLocal("nftFormData"));
      let localdata = getLocal("nftFormData");
      if (localdata) {
        this.nftFormData = localdata;
      }
    }
  },
  methods: {
    getLeaseOwnerEntity(creator_act_as, entity_name) {
      let entity = {
        address: this.user.address,
        email: this.user.email,
        type: this.$t("entityTypeList")["other"],
        phone: this.user.phone,
        fullname: this.user.name,
        user_id: this.user.id,
        allocation: 0,
        act_as: creator_act_as,
        is_creator: true,
      };
      return entity;
    },
    resetForm() {
      this.nftFormData.data = mintData;
      saveToLocal("nftFormData", null);
    },
    stepChanged(prevIndex, nextIndex) {
      // console.log("step changed: ", prevIndex, nextIndex, this.nftFormData);
      saveToLocal("nftFormData", this.nftFormData);
    },

    copyCreatorAllocation2EntityOld(allocs) {
      let final = [];
      let creator = null;
      let creatorEntity = null;

      for (let i = 0; i < allocs.length; i++) {
        let alloc = allocs[i];
        if (alloc.email == this.user.email && alloc.is_creator) {
          creator = alloc;
        } else {
          final.push(alloc);
        }
      }

      for (let i = 0; i < final.length; i++) {
        let alloc = final[i];
        if (alloc.email == this.user.email) {
          alloc.allocation = creator.allocation;
        }
      }
      return JSON.parse(JSON.stringify(final));
    },

    copyCreatorAllocation2Entity(allocs) {
      console.log("copy lease entties: ", this.lease.entities);
      let creatorAllocIndex = -1;
      let creatorAlloc = null;

      for (let i = 0; i < allocs.length; i++) {
        let alloc = allocs[i];

        if (alloc.is_creator) {
          creatorAlloc = alloc;
          creatorAllocIndex = i;
          break;
        }
      }

      // remove the creator alloc record
      allocs.splice(creatorAllocIndex, 1);

      let final = [];
      let creatorHasEntity = false;
      for (let i = 0; i < allocs.length; i++) {
        if (allocs[i].user_id == creatorAlloc.user_id) {
          allocs[i].allocation = creatorAlloc.allocation;
          creatorHasEntity = true;
        }
      }

      if (!creatorHasEntity) {
        // add back into the array so that server can create a new creator entity
        allocs.push(creatorAlloc);
      }

      // for (let i = 0; i < allocs.length; i++) {
      //   let alloc = allocs[i];

      //   if (!alloc.is_creator) {
      //     final.push(alloc);
      //   } else {
      //     let entities = this.lease.entities;
      //     for (let e = 0; e < entities.length; e++) {
      //       if (entities[e].user_id == alloc.user_id) {
      //         let entity = entities[e];
      //         entity.allocation = alloc.allocation;
      //         entity.is_creator = true;
      //         final.push(entity);
      //       }
      //     }
      //   }
      // }

      // return JSON.parse(JSON.stringify(final));
      return JSON.parse(JSON.stringify(allocs));
    },

    formSubmitted() {
      console.log("user: ", this.user);
      // console.log("nftFormData:", this.nftFormData);
      console.log("alloc before:", this.allocations);
      let allocs = this.copyCreatorAllocation2Entity(this.allocations);
      // let allocs = JSON.parse(JSON.stringify(this.allocations));
      // let allocs = this.allocations;

      console.log("allocs after:", allocs);
      this.submitNftMint(
        this.nftFormData,
        allocs, // this.allocations,
        this.$toast,
        this.leaseId
      );
      // test1(this.user, this.$toast);
    },

    submitSuccess(nft) {
      // update local data
      console.log("submit success", nft);
      // updateArrElemById(this.leases, "id", lease.id, lease);

      // update lease object with updated nft data
      init_leases.init();
      init_nfts.init();

      // clear form data
      saveToLocal("nftFormData", null);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Success",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },

    // // helper function to proper format form data for upload
    submitNftMint(formData, allocations, toast, leaseId) {
      console.log("submit nft mint: ", formData, allocations);
      // formData.user_id = user.id;
      let payload = {
        supply: formData.supply,
        contract: formData.contract,
        lease_id: leaseId,
        allocations: allocations,
      };

      this.isSubmitting = true;
      laravel.nfts
        .store(payload)
        .then((resp) => {
          console.log(resp);
          // clear local storage
          this.submitSuccess(resp.data);
          setTimeout(() => this.$router.push({ name: "nft-portfolio" }), 1000);

          this.isSubmitting = false;
        })
        .catch((error) => {
          console.log(error);
          this.isSubmitting = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + error.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.isSubmitting = false;
        });
    },
  },
};
</script>
