<template>
  <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0 h4">
          {{ $t("NftContractTypes.title") }}
        </h5>
        <small class="text-muted h6">
          {{ $t("NftContractTypes.subtitle") }}
        </small>
        <!-- modal -->
        <feather-icon
          v-b-modal.showInfoModal_NftContractTypes
          icon="InfoIcon"
          size="18"
        />
        <b-modal
          id="showInfoModal_NftContractTypes"
          size="lg"
          :title="$t('NftContractTypes.modal_title')"
          ok-only
          centered
          ok-title="Close"
        >
          <b-card-text class="">
            <span
              class="mb-2"
              v-for="(type, typeId) in $t('NftContractTypes.list')"
              :key="typeId"
            >
              <div v-if="typeId != 'select'">
                <h4>{{ type.text }}</h4>
                <p class="text-md">
                  {{ type.body }}
                  <br />
                  <router-link :to="type.url">{{ type.url_text }}</router-link>
                </p>
              </div>
            </span>
          </b-card-text>
        </b-modal>
      </b-col>

      <!-- select contract type -->
      <b-col cols="6">
        <h5 class="mb-1 h6">
          {{ $t("NftContractTypes.SelectLabel") }}
        </h5>

        <b-form-group label="" label-for="select_nft_contract_type">
          <b-form-select
            id="select_nft_contract_type"
            v-model="nftFormData.contract.contract_type"
            :options="nftContractTypes"
            size=""
            class=""
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import { getNameFromFileObj } from "@/libs/helpers";

import FormEntitiesSummary from "../form-entities/FormEntitiesSummary";
import FormLeaseTermsSummary from "@/views/forms/components/form-leases/FormLeaseTermsSummary";

import EntitiesListShort from "@/views/forms/components/form-entities/EntitiesListShort.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BFormTextarea,
    BCardText,
    BListGroup,
    BListGroupItem,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,

    FormEntitiesSummary,
    FormLeaseTermsSummary,
    EntitiesListShort,
  },
  props: {
    lease: {
      type: Object,
      default: null,
    },
    nftFormData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    entitiesArray() {
      return this.lease ? this.lease.entities : [];
    },
    nftContractTypes() {
      let final = [];
      let options = this.$t("NftContractTypes.list");
      let keys = Object.keys(this.$t("NftContractTypes.list"));
      for (let i = 0; i < keys.length; i++) {
        let option = options[keys[i]];
        // console.log("option: ", option);
        final.push(option);
      }
      // console.log("final: ", final);
      return final;
    },
  },
  methods: {
    formatMoney(amt) {
      return dollarUSLocale.format(amt);
    },
    getNameFromFileObj: function (fname) {
      return getNameFromFileObj(fname);
    },
  },
};
</script>
