<template>
  <div>
    <b-row>
      <b-col cols="12" class="mt-0">
        <small class="mb-0 h3 text-primary">
          {{ $t("Lease Value") }}
        </small>
        <hr />
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('Type of Property') + ':'"
          label-for="v-property-type"
        >
          <span class="h5 text-uppercase">
            {{ lease && lease.property_type ? lease.property_type : "" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('Type of Lease') + ':'"
          label-for="v-lease-type"
        >
          <span class="h5 text-uppercase">
            {{ lease && lease.lease_type ? lease.lease_type : "" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group :label="$t('Address') + ':'" label-for="v-lease-adress">
          <span class="h5 text-uppercase">
            {{ lease && lease.address ? lease.address : "" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Lease Start')" label-for="v-lease-start">
          <span class="h5 text-uppercase">
            {{
              lease && lease.start_date
                ? removeTimeFromDate(lease.start_date)
                : "None"
            }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Time Remaining')" label-for="v-lease-end">
          <span class="h5">
            {{ lease ? leaseTimeLeft : 0 }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Lease Amount')" label-for="v-lease-amount">
          <span class="h5">
            {{ $t("currencySign") }}
            {{ amount }}
            /
            {{ freq }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('Estimated Total Value')"
          label-for="v-lease-amount"
        >
          <span class="h5 text-uppercase">
            {{ $t("currencySign") }}
            {{ formatMoney(estTotalValue) }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="12 mb-2">
        <h3 class="mb-0 mt-2 h3 text-primary">
          {{ $t("NFT Config") }}
        </h3>
        <hr />
        <small class="h5 text-secondary">
          {{ $t("NFT Config Message") }}
        </small>

        <feather-icon icon="InfoIcon" size="18" v-b-modal.showInfoModal />
        <!-- modal -->
        <b-modal
          id="showInfoModal"
          size="lg"
          :title="$t('nft_config_info').title"
          ok-only
          centered
          ok-title="Close"
        >
          <b-card-text class="my-1">
            {{ $t("nft_config_info").body }}
          </b-card-text>
        </b-modal>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Shares NFTs')" label-for="supply">
          <cleave
            id="supply"
            v-model="nftFormData.supply"
            :raw="options.number.raw"
            class="form-control"
            :options="options.number"
            placeholder="100,000"
            min="1000"
            max="1000000"
          />
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group
          :label="$t('Est Value / NFT')"
          label-for="est-value-per-nft"
        >
          <span id="est-value-per-nft" class="h5 text-uppercase">
            {{ $t("currencySign") }}
            {{ formatMoney(showValuePerNft) }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group
          :label="$t('Est Income / NFT') + ' / ' + freq"
          label-for="est-value-per-nft"
        >
          <span id="est-value-per-nft" class="h5 text-uppercase">
            {{ $t("currencySign") }}
            {{ formatMoney(showNftIncomePerFreq) }}
          </span>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import { getNameFromFileObj, timeToGo, showInfoModal } from "@/libs/helpers";

import FormEntitiesSummary from "../form-entities/FormEntitiesSummary";
import FormLeaseTermsSummary from "@/views/forms/components/form-leases/FormLeaseTermsSummary";

import {
  estLeaseValue,
  dollarUSLocale,
  estValuePerNft,
  estIncomePerNftPerFreq,
  leaseDaysLeft,
} from "@/libs/financeHelper";

import Cleave from "vue-cleave-component";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BFormTextarea,
    BCardText,
    BListGroup,
    BListGroupItem,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,

    Cleave,

    FormEntitiesSummary,
    FormLeaseTermsSummary,
  },
  props: {
    // show summary for a card
    show_card: {
      type: Boolean,
      default: true,
    },

    nftFormData: {
      type: Object,
      required: true,
    },

    lease: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        number: {
          raw: true,
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
    };
  },
  mounted() {},
  computed: {
    showNftIncomePerFreq() {
      return estIncomePerNftPerFreq(
        this.showValuePerNft,
        this.daysLeft,
        this.freq
      );
    },
    freq() {
      return this.lease && this.lease.paym_freq ? this.lease.paym_freq : "";
    },
    amount() {
      return this.lease && this.lease.paym_freq_amt
        ? this.formatMoney(this.lease.paym_freq_amt)
        : 0;
    },
    daysLeft() {
      if (!this.lease || !this.lease.start_date) return 0;
      return leaseDaysLeft(this.lease);
    },
    leaseTimeLeft() {
      if (this.lease && this.lease.start_date && this.lease.end_date) {
        return timeToGo(this.lease.start_date, this.lease.end_date);
      } else {
        return "";
      }
    },
    estTotalValue() {
      if (this.lease) return estLeaseValue(this.lease);
      else return 0;
    },
    showValuePerNft() {
      // console.log("supply : ", this.nftFormData.supply);
      return estValuePerNft(this.estTotalValue, this.nftFormData.supply);
    },
  },
  methods: {
    formatMoney(amt) {
      return dollarUSLocale.format(amt);
    },
    getNameFromFileObj: function (fname) {
      return getNameFromFileObj(fname);
    },

    removeTimeFromDate: function (timeStr) {
      let dd = new Date(Date.parse(timeStr));
      return dd.getFullYear() + "-" + dd.getMonth() + "-" + dd.getDate();
    },
  },
};
</script>
