<template>
  <div>
    <b-row>
      <b-col cols="12" class="mt-1 mb-2">
        <!-- <h4 class="mb-1 h4 text-primary" v-if="$t('Initial Allocation Title')">
          {{ $t("Initial Allocation Title") }}
        </h4> -->
        <h5 class="mb-1 h5 text-secondary">
          {{ $t("Initial Allocation Subtitle") }}
        </h5>

        <span class="text-sm">
          {{ $t("Initial Allocation Explain Creator") }}
        </span>
      </b-col>

      <b-col cols="3">
        <b-form-group
          :label="$t('total_nft_available_for_allocation')"
          label-for="est-value-all-nft-supply"
        >
          <span id="est-value-all-nft-supply" class="h5 text-uppercase">
            {{ formatMoney(nftFormData.supply) }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="5">
        <b-form-group
          :label="$t('Estimated Total Value')"
          label-for="est-value-all-nft"
        >
          <span id="est-value-all-nft" class="h5 text-uppercase">
            {{ $t("currencySign") }}
            {{ formatMoney(estTotalValue) }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group :label="$t('Lease Amount')" label-for="v-lease-amount">
          <span class="h5">
            {{ $t("currencySign") }}
            {{ amount }}
            /
            {{ freq }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="mb-2">
        <b-list-group>
          <b-list-group-item
            v-for="(entity, index) in allocations"
            :id="entity.name"
            :key="index"
            ref="row"
            class="flex-column align-items-start"
            v-show="!isCreator(entity)"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1 text-primary text-uppercase">
                <span>
                  {{ $t(entity.act_as) }}
                </span>
              </h5>
              <small class="text-secondary h6">
                {{ entity.fullname }}
              </small>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <div class="text-secondary text-sm">{{ entity.email }}</div>
            </div>

            <b-card-text class="mb-0">
              <div class="row">
                <div class="col-xs-12 col-sm-4">
                  <b-form-group
                    label=""
                    :label-for="'select_nft_allocation_ent_' + entity.name"
                  >
                    <b-form-input
                      :id="'select_nft_allocation_ent_' + entity.name"
                      v-model="entity.allocation"
                      size=""
                      type="number"
                      placeholder="50,000"
                    />
                  </b-form-group>
                </div>
                <div class="col-xs-12 col-sm-8">
                  <b-button-group class="">
                    <b-button
                      @click="assignAllocation(entity, option)"
                      v-for="option in alloc_options"
                      :key="option.percent"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                    >
                      {{ Math.round(option.percent * 100) }}%
                    </b-button>
                  </b-button-group>
                </div>

                <div class="col-xs-12 col-sm-12 mt-1">
                  <small class="text-secondary h6">
                    {{ $t("Est Value Of Allocation") }}
                  </small>
                </div>

                <div class="col-12 col-sm-6 col-md-6">
                  <b-form-group
                    :label="$t('Est Value of Alloation')"
                    label-for="est-value-per-nft-value-alloc"
                  >
                    <span
                      id="est-value-per-nft-value-alloc"
                      class="h5 text-uppercase"
                    >
                      {{ $t("currencySign") }}
                      {{ formatMoney(showValuePerAlloc(entity)) }}
                    </span>
                  </b-form-group>
                </div>

                <div class="col-12 col-sm-6 col-md-6">
                  <b-form-group
                    :label="$t('Est Allocation Income') + ' / ' + freq"
                    label-for="est-value-per-nft"
                  >
                    <span id="est-value-per-nft" class="h5 text-uppercase">
                      {{ $t("currencySign") }}
                      {{ formatMoney(showAllocIncomePerFreq(entity)) }}
                    </span>
                  </b-form-group>
                </div>
              </div>
            </b-card-text>
          </b-list-group-item>

          <!-- creator entity -->
          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1 text-primary text-uppercase">
                <span>
                  {{ $t(creatorAllocEntity ? creatorAllocEntity.act_as : "") }}
                  ({{ $t("Lease Creator") }})
                </span>
              </h5>
              <small class="text-secondary h6">
                {{ creatorAllocEntity ? creatorAllocEntity.fullname : "" }}
              </small>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <div class="text-secondary text-sm">
                {{ creatorAllocEntity ? creatorAllocEntity.email : "" }}
              </div>
            </div>

            <b-card-text class="mb-0">
              <div class="row">
                <div class="col-xs-12 col-sm-4">
                  <b-form-group label="">
                    <span>
                      {{ creatorAllocation }}
                    </span>
                  </b-form-group>
                </div>
                <div class="col-xs-12 col-sm-8"></div>

                <div class="col-xs-12 col-sm-12 mt-1">
                  <small class="text-secondary h6">
                    {{ $t("Est Value Of Allocation") }}
                  </small>
                </div>

                <div class="col-12 col-sm-6 col-md-6">
                  <b-form-group
                    :label="$t('Est Value of Alloation')"
                    label-for="est-value-per-nft-value-alloc"
                  >
                    <span
                      id="est-value-per-nft-value-alloc"
                      class="h5 text-uppercase"
                    >
                      {{ $t("currencySign") }}
                      {{ formatMoney(showValuePerAlloc(creatorAllocEntity)) }}
                    </span>
                  </b-form-group>
                </div>

                <div class="col-12 col-sm-6 col-md-6">
                  <b-form-group
                    :label="$t('Est Allocation Income') + ' / ' + freq"
                    label-for="est-value-per-nft"
                  >
                    <span id="est-value-per-nft" class="h5 text-uppercase">
                      {{ $t("currencySign") }}
                      {{
                        formatMoney(showAllocIncomePerFreq(creatorAllocEntity))
                      }}
                    </span>
                  </b-form-group>
                </div>
              </div>
            </b-card-text>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BCard,
  BListGroup,
  BListGroupItem,
  BButtonGroup,
} from "bootstrap-vue";

import { getNameFromFileObj, timeToGo, showInfoModal } from "@/libs/helpers";

import FormEntitiesSummary from "../form-entities/FormEntitiesSummary";
import FormLeaseTermsSummary from "@/views/forms/components/form-leases/FormLeaseTermsSummary";
import { sync } from "vuex-pathify";
import {
  estLeaseValue,
  dollarUSLocale,
  estValuePerNft,
  estIncomePerNftPerFreq,
  leaseDaysLeft,
} from "@/libs/financeHelper";

import Cleave from "vue-cleave-component";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BFormTextarea,
    BCardText,
    BListGroup,
    BListGroupItem,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,
    BButtonGroup,

    Cleave,

    FormEntitiesSummary,
    FormLeaseTermsSummary,
  },
  props: {
    allocations: {
      type: Array,
      required: true,
    },
    nftFormData: {
      type: Object,
      required: true,
    },
    lease: {
      type: Object,
      default: null,
    },
  },
  watch: {},
  data() {
    return {
      // guarantors: [],
      alloc_options: [
        {
          percent: 0.0,
        },
        {
          percent: 0.25,
        },
        {
          percent: 0.5,
        },
        {
          percent: 0.75,
        },
        {
          percent: 1.0,
        },
      ],
    };
  },
  mounted() {},
  computed: {
    user: sync("user/profile"),

    creatorAllocEntity() {
      for (let i = 0; i < this.allocations.length; i++) {
        if (this.allocations[i].is_creator) {
          return this.allocations[i];
        }
      }
      return null;
    },
    creatorAllocation() {
      let total = this.nftFormData.supply;
      let creator = null;
      for (let i = 0; i < this.allocations.length; i++) {
        if (!this.allocations[i].is_creator) {
          total -= this.allocations[i].allocation;
        } else {
          creator = this.allocations[i];
        }
      }
      if (!creator) {
        return 0;
      } else {
        creator.allocation = total;
        return total;
      }
    },
    entities() {
      return this.lease ? this.lease.entities : [];
    },
    showNftIncomePerFreq() {
      return estIncomePerNftPerFreq(
        this.showValuePerNft,
        this.daysLeft,
        this.freq
      );
    },
    freq() {
      return this.lease && this.lease.paym_freq ? this.lease.paym_freq : "";
    },
    amount() {
      return this.lease && this.lease.paym_freq_amt
        ? this.formatMoney(this.lease.paym_freq_amt)
        : 0;
    },
    daysLeft() {
      if (!this.lease || !this.lease.start_date) return 0;
      return leaseDaysLeft(this.lease);
    },
    leaseTimeLeft() {
      if (this.lease && this.lease.start_date && this.lease.end_date) {
        return timeToGo(this.lease.start_date, this.lease.end_date);
      } else {
        return "";
      }
    },
    estTotalValue() {
      if (this.lease) return estLeaseValue(this.lease);
      else return 0;
    },
    showValuePerNft() {
      // console.log("supply : ", this.nftFormData.supply);
      return estValuePerNft(this.estTotalValue, this.nftFormData.supply);
    },
  },
  methods: {
    isCreator(entity) {
      // console.log("entity is creator? ", entity);
      if (entity && entity.email && entity.email == this.user.email) {
        return true;
      } else {
        return false;
      }
    },
    showValuePerAlloc(entity) {
      if (!entity) {
        return 0;
      } else {
        return (
          entity.allocation *
          estValuePerNft(this.estTotalValue, this.nftFormData.supply)
        );
      }
    },
    showAllocIncomePerFreq(entity) {
      if (!entity) {
        return 0;
      } else {
        return (
          entity.allocation *
          estIncomePerNftPerFreq(this.showValuePerNft, this.daysLeft, this.freq)
        );
      }
    },
    assignAllocation(entity, option) {
      // console.log("assign allocation: ", entity, option);
      entity.allocation = this.nftFormData.supply * option.percent;
      // this.$emit("input");
      // let elem = document.getElementById(
      //   "select_nft_allocation_ent_" + entity ? entity.name : ""
      // );
      // elem.value = entity.allication;
      // console.log("dispatch", elem);
      // elem.dispatchEvent(new Event("input"));
      // this.$set(entity, "allocation", this.nftFormData.supply * option.percent);
    },
    formatMoney(amt) {
      return dollarUSLocale.format(amt);
    },
    getNameFromFileObj: function (fname) {
      return getNameFromFileObj(fname);
    },

    removeTimeFromDate: function (timeStr) {
      let dd = new Date(Date.parse(timeStr));
      return dd.getFullYear() + "-" + dd.getMonth() + "-" + dd.getDate();
    },
  },
};
</script>
